import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

// markup
const ContentPageTemplate = ({ data }) => {
  const pageContent = data.pageContent.nodes[0];

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div className="container d-flex justify-content-center py-5">
        <div
          className="content-wrapper"
          dangerouslySetInnerHTML={{ __html: pageContent.html }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query contentPageQuery($slug: String!) {
    pageContent: allMarkdownRemark(
      filter: { fields: { slug: { eq: $slug } } }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
  }
`;

export default ContentPageTemplate;
